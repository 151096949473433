import { render, staticRenderFns } from "./DispatchOverview.vue?vue&type=template&id=2aec8263&scoped=true&"
import script from "./DispatchOverview.vue?vue&type=script&lang=js&"
export * from "./DispatchOverview.vue?vue&type=script&lang=js&"
import style0 from "./DispatchOverview.vue?vue&type=style&index=0&id=2aec8263&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aec8263",
  null
  
)

export default component.exports
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QFab,QFabAction,QTooltip});
