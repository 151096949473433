<template>
<div id='pdf-reports-template'>
	<div style="font-family: 'Poppins'; sans-serif; font-size: 14px;line-height: 1.4;">
		<div style="display: flex; flex-wrap: wrap;">
			<div class="header-wrapper">
				<div><strong>Message ID: </strong>{{report_details.id}}</div>
				<div><strong>Message name: </strong>{{report_details.name}}</div>
				<div><strong>Message type: </strong>{{ session_type }}</div>
				<div><strong>Started by: </strong>{{report_details.started_by}}</div>
				<div v-if='report_details.date_start'><strong>Start time: </strong>
					<span>{{date_start | moment('timezone', preferred_timezone, 'DD/MM/YYYY HH:mm')}} </span>
				</div>
				<div v-if="session_type !== 'Conference'">
					<strong>Polling used? </strong>
					<span v-if="session_settings.polling_options"> Yes</span>
					<span v-else> No</span>
				</div>
				<div v-if="!is_only_email"><strong>Authentication used? </strong>
					<span v-if="polling.authentication">Yes</span>
					<span v-else>No</span>
				</div>
				<div v-if="!is_only_email"><strong>Separate messages used for answering services? </strong>
					<span v-if="polling.separate_amd_live_message">Yes</span>
					<span v-else>No</span>
				</div>
			</div>
			<div class="header-wrapper-small">
				<div>
					<strong> Recipients:</strong> <br>
					<span style="font-size:34px;"><strong>{{user_stats_data.total_recipients}}</strong></span>
				</div>
			</div>
			<div class="header-wrapper-small">
				<strong> Excluded:</strong> <br>
				<span style="font-size:34px;"><strong>{{user_stats_data.excluded_users}}</strong></span>
			</div>
			<div class="header-wrapper">
				<div v-if="session_settings.VOICE && session_settings.VOICE['message_amd']!= undefined"><strong>Answering machine:&nbsp;</strong>
					<span>{{session_settings.VOICE.message_amd}}</span>
				</div>
				<div v-if="session_settings.VOICE && session_settings.VOICE.message != undefined"><strong>Live answer:&nbsp;</strong>
					<span>{{session_settings.VOICE.message}}</span>
				</div>
				<div v-if="session_settings.SMS != undefined"><strong>SMS text:&nbsp;</strong>
					<span>{{session_settings.SMS.message}}</span>
				</div>
				<div v-if="session_settings.EMAIL != undefined"><strong>Email:&nbsp;</strong>
					<span>{{session_settings.EMAIL.message}}</span>
				</div>
			</div>
			<div class="header-wrapper-small">
				<div>
					<strong> Reached:</strong> <br>
					<span style="font-size:34px;"><strong>{{user_stats_data.delivered_recipients}}</strong></span><br>
					<span style="font-size:34px;"><strong>{{percentage_delivered}}%</strong></span>
				</div>
			</div>
			<div class="header-wrapper-small" v-if="report_details.settings.polling_options || session_type === 'Conference'">
				<span v-if="session_type === 'Conference'"><strong>Accepted </strong> <br></span>
				<span v-else><strong>Responded: </strong> <br></span>
				<span style="font-size:34px;"><strong>{{user_stats_data.total_responded_recipients}}</strong></span><br>
				<span style="font-size:34px;"><strong>{{percentage_responded}}%</strong></span>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';
import DispatchDetailsAPI from '@/services/api/DispatchDetails.js';

export default {
	name: 'OverviewPrint',
	data(){
		return {
			session_settings: {},
			date_start: "",
			preferred_timezone: "",
			polling: {},
			is_only_email: false,
			total_recipients: 0,
		}
	},
	computed: {
		...mapGetters(['logged_user', 'report_details', 'user_stats_data']),
		session_type () {
			return this.report_details.session_type.charAt(0).toUpperCase() + this.report_details.session_type.slice(1);
		},
		percentage_delivered(){
			if (this.user_stats_data.delivered_recipients && this.user_stats_data.total_recipients) {
				let percent = ((this.user_stats_data.delivered_recipients / this.user_stats_data.total_recipients) * 100)
				return Math.round(percent * 10) / 10
			}
			return 0
		},
		percentage_responded(){
			if (this.user_stats_data.delivered_recipients && this.user_stats_data.total_recipients) {
				if (this.report_details.settings.polling_options || this.session_type === 'Conference'){
					let percent_respond = ((this.user_stats_data.total_responded_recipients / 
						this.user_stats_data.delivered_recipients) * 100)
					if (percent_respond){
						return Math.round(percent_respond * 10) / 10
					}
					return 0
				}
			}
		}
	},
	async created(){
		this.session_settings = this.report_details.settings;
		this.date_start = moment.tz(this.report_details.date_start, 'UTC');
		if (this.logged_user) {
			this.preferred_timezone = this.logged_user.preferred_timezone;
		}

		/* Set up "Separate AMD / Live Messages" switch */
		if(this.session_settings.separate_call == true && (this.session_settings.VOICE != undefined && this.session_settings.VOICE != null)){
			this.polling['separate_amd_live_message'] = true;	
		}
		else if(this.session_settings.separate_call == true && (this.session_settings.VOICE == undefined || this.session_settings.VOICE == null)){
			this.polling['separate_amd_live_message'] = false;
		}

		if((this.session_settings.VOICE == undefined || this.session_settings.VOICE == null) && (this.session_settings.EMAIL != undefined && this.session_settings.EMAIL != null)){
			this.is_only_email = true;
		}

		else{
			this.is_only_email = false;
		}
		let recipients = await DispatchDetailsAPI.aggregate(this.$route.params.id, 'reply');
		for(let i in recipients){
			this.total_recipients += recipients[i].count;
		}
	},
}
</script>
<style>

</style>