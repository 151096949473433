<template>
	<div  :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<SessionDetails
			v-if="report_details_ready"
			:chartDatas="chartDatas"
			:overview_report="overview_report"
			:preferred_timezone="preferred_timezone"
			:is_overview="true"
		/>
		<div class="row justify-evenly ">
			<div
				v-for="(obj, index) in chartDatas"
				:key="index"
				class="col-md-6 col-12"
			>
				<PieOverview
					:data='obj.data'
					:title='obj.title'
					:styles='pieStyle'
					:session_type='report_details.session_type'
				/>
			</div>
		</div>
		<div class="field-fab-wrapper" :class="$q.screen.lt.sm && !show_visit_detaild ? 'button-display' : ''">
			<q-fab
				class="flex justify-center q-mt-md"
				style="position: sticky; bottom: 50px;"
				:label="$q.screen.gt.sm ? 'Actions' : ''"
				label-position="left"
				color="primary"
				icon="keyboard_arrow_up"
				direction="up"
			>
				<q-fab-action
					class="button-display"
					color="primary"
					icon="print"
					:label="$q.screen.gt.sm ? 'Print' : ''"
					@click="printChart()"
				>
					<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
						Print
					</q-tooltip>
				</q-fab-action>
				<q-fab-action
					v-if="show_visit_detaild"
					color="primary"
					icon="list_alt"
					:label="$q.screen.gt.sm ? 'Visit details' : ''"
					:to="{ name: 'Dispatch Details', params: {id: report_details.id }}"
				>
					<q-tooltip v-if="!$q.screen.gt.sm" anchor="top middle" :delay="500">
						Visit details
					</q-tooltip>
				</q-fab-action>
			</q-fab>
		</div>
		<OverviewPrint
			v-if="report_details_ready"
			class="print-preview"
		/>
	</div>
</template>

<script>
import PieOverview from '@/components/scenarios/message_reports/overview_reports/PieOverview.vue';
import SessionDetails from '@/components/scenarios/message_reports/detailed_reports/SessionDetails.vue';
import OverviewPrint from '@/components/scenarios/message_reports/overview_reports/OverviewPrint.vue';
import DispatchDetailsAPI from '@/services/api/DispatchDetails.js';
import OverviewPrintStyles from '@/utils/print_overview_report_style.js';
import { mapActions, mapGetters } from 'vuex';

import moment from 'moment-timezone';

export default {
	name: 'DispatchOverview',
	meta: {
		title: 'Alert Cascade - Overview Report'
	},
	components: {
		SessionDetails,
		PieOverview,
		OverviewPrint
	},
	data() {
		return {
			chartDatas: [],
			report_details_ready: false,
			overview_report: true,
			session_settings: {},
			report_details_interval: "",
			reply_time: [],
			on_time_reply: [],
			late_time_reply: [],
			on_time_reply_by_channel: [],
			late_reply_by_channel: [],
			preferred_timezone: "",
			show_visit_detaild: false
		};
	},
	computed: {
		...mapGetters(['report_details', 'logged_user', 'current_CG']),
		pieStyle() {
			return {
				height: '350px',
				position: 'relative',
				width: '100%'
			};
		},
	},
	methods: {
		...mapActions(["getReportDetails"]),
		printChart() {
			let preferred_timezone = this.logged_user.preferred_timezone
			let date_now = moment.utc().tz(preferred_timezone).format('DD/MM/YYYY HH:mm')
			var canvasEle = document.getElementsByClassName('chartjs-render-monitor');
			var win = window.open("", "", "height=600, width=600");
			win.document.open()
			win.document.write(
				'<html><head><title>Alert Cascade Overview Report ' + this.$route.params.id + '</title>'
				+ OverviewPrintStyles.getOverviewReportStyles() +
				'</head><body>'
			);
			win.document.write(
				"<div style='text-align:end;font-size:12px;'>" + date_now + "</div>" +
				"<h4 style='text-align:center;'>Alert Cascade Overview Report " + this.$route.params.id + "</h4>"
			)
			win.document.x = window.parent.document.x = `Alert Cascade Overview Report ${this.$route.params.id}`
			const printContent = document.getElementById("pdf-reports-template").innerHTML;
			win.document.write('<div>' + printContent);
			win.document.write('<div style="display:flex; justify-content:center; flex-wrap:wrap; margin-top:8px;">');
			for (let i = 0; i < canvasEle.length; i++) {
				if (canvasEle.length == 1){
					win.document.write("<div style='max-width:100%;'><img width='100%;' src='" + canvasEle[i].toDataURL() +"'/></div>");
				}else if(canvasEle.length > 1){
					win.document.write("<div style='max-width:50%;'><img width='100%;' src='" + canvasEle[i].toDataURL() +"'/></div>");
				}
			}
			win.document.write('</div></div></body></html>')

			setTimeout(function () { //giving it 200 milliseconds time to load
				win.document.close();
				win.focus();
				win.print();
			}, 200);
		},
		async getChartData() {
			await this.getReportDetails(this.$route.params.id);
			this.session_settings = this.report_details.settings;
			this.delivery = await DispatchDetailsAPI.aggregate(this.$route.params.id, 'state');

			if (this.session_settings.polling_options) {
				this.reply_time = await DispatchDetailsAPI.aggregate(this.$route.params.id, 'reply_time');
				this.on_time_reply = await DispatchDetailsAPI.aggregate(this.$route.params.id, 'on_time_reply');
				this.late_time_reply = await DispatchDetailsAPI.aggregate(this.$route.params.id, 'late_reply');
				this.on_time_reply_by_channel = await DispatchDetailsAPI.aggregate(this.$route.params.id, 'on_time_reply_by_channel');
				this.late_reply_by_channel = await DispatchDetailsAPI.aggregate(this.$route.params.id, 'late_reply_by_channel');
			}

			this.create_chart_data();
		},
		create_chart_data() {
			this.chartDatas = [];
			this.chartDatas.push({ "title": "Deliveries", "data": this.delivery });
			let reply_time_without_failed_dispatches = [];

			for (let i = 0; i < this.reply_time.length; i++) {
				if (this.reply_time[i].label != 'Delivery Failure') {
					reply_time_without_failed_dispatches.push(this.reply_time[i]);
				}
			}
			if (reply_time_without_failed_dispatches.length && (this.report_details.session_type == 'broadcast' || this.report_details.session_type == 'sequence')) {
				this.chartDatas.push({ "title": "Responses", "data": reply_time_without_failed_dispatches });
			}
			if (this.on_time_reply.length && (this.report_details.session_type == 'broadcast' || this.report_details.session_type == 'sequence')) {
				this.chartDatas.push({ "title": "On Time Response - by option", "data": this.on_time_reply });
			}
			if (this.late_time_reply.length && (this.report_details.session_type == 'broadcast' || this.report_details.session_type == 'sequence')) {
				this.chartDatas.push({ "title": "Late Response - by option", "data": this.late_time_reply });
			}
			if (this.on_time_reply_by_channel.length && (this.report_details.session_type == 'broadcast' || this.report_details.session_type == 'sequence')) {
				this.chartDatas.push({ "title": "On Time Response - by channel", "data": this.on_time_reply_by_channel });
			}
			if (this.late_reply_by_channel.length && (this.report_details.session_type == 'broadcast' || this.report_details.session_type == 'sequence')) {
				this.chartDatas.push({ "title": "Late Response - by channel", "data": this.late_reply_by_channel });
			}
		}
	},
	updated() {
		var a = moment(this.report_details.date_start).add(4, 'hours');
		var b = moment.tz('UTC');//now in UTC
		var is_after = b.isAfter(a);
		if (is_after) {
			clearInterval(this.report_details_interval);
			this.report_details_interval = undefined;
		}
	},
	created() {
		if (this.logged_user) {
			this.preferred_timezone = this.logged_user.preferred_timezone;
		}
		this.getChartData();
		this.report_details_interval = setInterval(() => {
			this.getChartData();
		}, 15000);
		let operator_ea1 = 'Allow Operators to view address string (detailed reports)'
		let operator_ea2 = 'Allow Operators to view all info (detailed reports)'
		this.show_visit_detaild = this.logged_user.customer_permission == 'ADMIN' || (
			[ 'OPERATOR', 'SENDER'].includes(this.logged_user.customer_permission) &&
			(this.current_CG.root_customer_group_permissions.indexOf(operator_ea1) > -1 ||
			this.current_CG.root_customer_group_permissions.indexOf(operator_ea2) > -1)
		)
	},
	watch: {
		report_details: {
			deep: true,
			handler() {
				this.report_details_ready = !!Object.keys(this.report_details).length;
			}
		}
	},
	beforeDestroy() {
		clearInterval(this.report_details_interval);
	}
}
</script>

<style lang="scss" scoped>
	.router-view-wrapper {
		padding: 1.5rem 3rem;
		background-color: #fff;
		@media (max-width: 768px) {
			padding: 1rem 0.5rem;
		}
	}
	.print-preview{
		display: none;
	}
	@media (max-width: 599px) {
		.button-display {
			display: none;
		}
	}
	.field-fab-wrapper {
		position: sticky;
		bottom: 20px;
		z-index: 5;
		width: fit-content;
		margin: 10px auto;
	}
</style>
