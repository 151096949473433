
<script>
import { Doughnut } from 'vue-chartjs'
import { mapGetters } from 'vuex';
export default{
	extends: Doughnut,
	props: ["data", "title", "styles", "session_type"],
	data(){
		return {
			chartData: {
				labels: [],
				datasets: [],
			},
			options: this.createOptions()
		}
	},
	computed: {
		...mapGetters(['logged_user', 'current_CG']),
		is_operator_extended_access_AS() {
			const operator_ea = "Allow Operators to view all info (detailed reports)";
			const operator_ea1 = "Allow Operators to view address string (detailed reports)";
			let root_cg_permissions = this.current_CG.root_customer_group_permissions;

			return this.logged_user.customer_permission == "OPERATOR" && (root_cg_permissions.indexOf(operator_ea) > -1 || root_cg_permissions.indexOf(operator_ea1) > -1);
		},
	},
	mounted() {
		this.renderChart(this.chartData, this.options);
	},
	methods: {
		handle (evt, data) {
			let section = "";
			if(data[0]){
				let filter_value = this.chartData.labels[data[0]._index];
				var separator_index = filter_value.lastIndexOf(":");
				let filter = filter_value.substring(0,separator_index);
				if (filter == 'Delivered'){ filter = 'Successful Delivery' }
				if (filter == 'Joined'){ filter = 'Successful Connection' }
				if (filter == 'Processing'){ filter = 'Still Processing' }
				if (filter == 'Excluded'){ filter = 'Excluded Users' }
				if (filter == 'Rejected'){ filter = 'Conference Rejected' }
				if (filter == 'Failed'){
					if (this.session_type !== 'conference'){ filter = 'Delivery Failure' }
					else{ filter = 'Failed Connection' }
				}
				if (
						(this.logged_user.customer_permission == 'ADMIN' || this.is_operator_extended_access_AS) &&
						filter.indexOf("EMAIL") == -1 &&
						filter.indexOf("SMS") == -1 &&
						filter.indexOf("VOICE") == -1
					){
						if(filter=== 'Delivery Failure' || filter === 'Successful Delivery' || filter === 'Sent' || filter === 'Still Processing') {
							section = 'state';
						}else if(filter=== 'On Time Response' || filter === 'Late Response' || filter === 'No Response'){
							section = 'reply_time';
						}else{
							section = 'reply';
						}
						this.$router.push({ name: 'Dispatch Details', query: { section: section, filter: filter }});
					}
			}
		},
		createOptions(){
			return {
				maintainAspectRatio: false,
				onClick: this.handle,
				legend: {
					align: 'left',
					position: 'bottom',
					labels: {
						generateLabels: function(chart) {
							var labels = chart.data.labels;
							var ds = chart.data.datasets[0];
							var legend = labels.map(function(label, index) {
							const meta = chart.getDatasetMeta(0);
							const style = meta.controller.getStyle(index);
							if (label.length > 50){
								label = label.substring(0, 50) + ' ... ' + label.substring(label.length - 5)
							}
							return {
								datasetIndex: 0,
								fillStyle: style.backgroundColor,
								strokeStyle: style.borderColor,
								lineWidth: style.borderWidth,
								hidden: isNaN(ds.data[index]) || meta.data[index].hidden,
								index: index,
								text: label,
							}
							});
							return legend;
						},
					},
				},
				animation: {
					duration: 0, // disable the default animation
				},
				title: {
					display: true,
					text: this.title,
					fontColor: 'black'
				},
				tooltips: {
					callbacks: {
						label: function(tooltipItem, data) {
							const dataset = data.datasets[tooltipItem.datasetIndex];
							const meta = dataset._meta[Object.keys(dataset._meta)[0]];
							const total = meta.total;
							const currentValue = dataset.data[tooltipItem.index];
							const percentage = parseFloat((currentValue/total*100).toFixed(1));
							return currentValue + ' (' + percentage + '%)';
						},
						title: function(tooltipItem, data) {
							let label = data.labels[tooltipItem[0].index].split(":");
							return label[0];
						}
					}
				},
				plugins: {
					datalabels: {
						formatter: (value, ctx) => {
							let object_keys = Object.keys(ctx.dataset._meta)
							let sum = ctx.dataset._meta[object_keys].total;
							let percentage = (value * 100 / sum).toFixed(1) + "%";
							if((value * 100 / sum) >= 3){
								return percentage;
							}else{
								return "";
							}
						},
						color: '#fff',
						anchor: 'center',
						align: 'end',
						display: 'flex'
					}
				},
			}
		},
		createChartData(){
			let data_count_array = [];
			let background_color = [];
			let hover_color = [];
			let color_pattern = {
				'Sent': '#996d11',
				'Successful Delivery': '#4b9911',
				'Conference Rejected': '#5f1199',
				'Successful Connection': '#4b9911',
				'Failed Connection': '#991b11',
				'Still Processing': '#5f1199',
				'Delivery Failure': '#991b11',
				'On Time Response': '#4b9911',
				'Late Response': '#996d11',
				'No Response': '#99118f',
				'Non-matching Reply': '#5f1199',
				'Successful Authentication': '#4b9911',
				'Authentication Failure': '#991b11',
				'Excluded Users': '#B2B2B2' ,
			};
			let hover_colors_pattern = {
				'Sent': '#a17312',
				'Successful Delivery': '#50a112',
				'Successful Connection': '#50a112',
				'Conference Rejected': '#6312a1',
				'Failed Connection': '#a11b12',
				'Still Processing': '#6312a1',
				'Delivery Failure': '#a11b12',
				'On Time Response': '#50a112',
				'Late Response': '#a17312',
				'No Response': '#a11297',
				'Non-matching Reply': '#6312a1',
				'Successful Authentication': '#50a112',
				'Authentication Failure': '#a11b12',
				'Excluded Users': '#bfbfbf' ,
			};
			let colors = Object.keys(color_pattern);

			for (var i=0; i < this.data.length; i++){
				var data_count = this.data[i].count;
				data_count_array.push(data_count);
				let label_name = this.data[i].label
				if (label_name == 'Successful Delivery'){ label_name = 'Delivered' }
				if (label_name == 'Successful Connection'){ label_name = 'Joined' }
				if (label_name == 'Delivery Failure'){ label_name = 'Failed' }
				if (label_name == 'Still Processing'){ label_name = 'Processing' }
				if (label_name == 'Excluded Users'){ label_name = 'Excluded' }
				if (label_name == 'Failed Connection'){ label_name = 'Failed' }
				if (label_name == 'Conference Rejected') { label_name = 'Rejected' }
				// Successful Connection
				let label = label_name +": "+ data_count;
				this.chartData.labels.push(label);
				for(let color in colors){
					if(colors[color] === this.data[i].label){
						background_color.push(color_pattern[colors[color]]);
						hover_color.push(hover_colors_pattern[colors[color]]);
					}
				}
			}

			if(!background_color.length){
				background_color.push('rgb(31,119,180)', 'rgb(219, 110, 13)', 'rgb(44,160,44)', 'rgb(214,39,40)', 'rgb(148,103,189)', 'rgb(140,85,74)',
				'rgb(227,119,194)', 'rgb(127,127,127)', 'rgb(188,189,34)', 'rgb(94,191,207)');
			}
			if(!hover_color.length){
				hover_color.push('rgb(51,139,200)', 'rgb(232, 118, 16)', 'rgb(64,180,64)', 'rgb(234,59,60)', 'rgb(168,123,209)', 'rgb(160,105,94)',
				'rgb(247,139,214)', 'rgb(147,147,147)', 'rgb(208,209,54)', 'rgb(114,211,227)');
			}
			let obj = {
				label: "Overview Report",
				backgroundColor: background_color,
				hoverBackgroundColor: hover_color,
				data: data_count_array,
				width: 200,
				borderWidth: false,
			}
			this.chartData.datasets.push(obj);
		}
	},
	created() {
		this.createChartData();
	},
	watch: {
		data: function(){
			this.chartData= {
				labels: [],
				datasets: [],
			},
			this.options = {}
			this.createChartData();
			this.options = this.createOptions();
			this.renderChart(this.chartData, this.options);
		}
	}
}
</script>

<style scoped>
</style>
