export default {
	getOverviewReportStyles() {
		return styles;
	},
};

const styles = `
	<style>
		header {
			position: fixed;
			top: 0;
			left: 0;
			height: 2.5cm;
			width: 100%;
			margin-top: -50px;
		}

		.page-break{
			display: block;
			page-break-after: always;
		}

		.header-wrapper{
			margin: 8px;
			width: 40%;
			padding: 8px;
			border-radius: 4px;
			background: #fff;
			box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
			word-break: break-all;
		}

		.header-wrapper-small{
			margin: 8px;
			width: 20%;
			padding: 8px;
			border-radius: 4px;
			background: #fff;
			box-shadow: 0 1px 5px rgb(0 0 0 / 20%), 0 2px 2px rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%);
		}
		@page
		{
			size:  auto;   /* auto is the initial value */
			margin: 0mm;  /* this affects the margin in the printer settings */
		}
	</style>
`;
